import { ifAuthenticatedAndAllowed } from "../index"

// import {
//   NotificationManagementList, NotificationManagementCustomerListingDetail,
//   PassThrough,
// } from "../../constants/component-imports"

import {
  LAYOUT_ADMIN
} from "../../constants";


const notificationManagementRouter = {
  path: "/notification-management",
  component: () => import("@/views/pass-through.vue"),
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_LOGISTICS_OFFICER'
    ],
  },
  children: [
    {
      path: ":index?",
      name: "Notifications List",
      component: () => import("@/views/notification-management/list.vue"),
      beforeEnter: (to, from, next) => ifAuthenticatedAndAllowed(to, from, next),
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_LOGISTICS_OFFICER'
        ],
        title: "Notification Management",
        subtitles: [""]
      },
    },
    {
      path: "customer-detail/:id",
      name: "Customer Detail",
      component: () => import("@/views/notification-management/customerDetail.vue"),
      beforeEnter: (to, from, next) => ifAuthenticatedAndAllowed(to, from, next),
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_LOGISTICS_OFFICER'
        ],
        title: "Notification Management",
        subtitles: [""]
      },
    },
  ],
  beforeEnter: (to, from, next) => ifAuthenticatedAndAllowed(to, from, next),
};

export default notificationManagementRouter;