import request from "@/utils/request";
import { DEFAULT_TIMEOUT } from "../constants/index";

import * as apiConstants from "../constants/services";

export async function getInternalNotifications(postData) {
  return await request({
    url: apiConstants.API_GET_INTERNAL_NOTIFICATIONS,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function postInternalNotifications(postData) {
  return await request({
    url: apiConstants.API_POST_INTERNAL_NOTIFICATIONS,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getCustomerList(postData) {
  return await request({
    url: apiConstants.API_GET_CUSTOMER_LIST,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function postCustomer(postData) {
  return await request({
    url: apiConstants.API_POST_CUSTOMER,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}