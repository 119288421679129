import cloneDeep from 'lodash/cloneDeep'
import * as shipmentActions from "./actions";
import * as shipmentApis from "../../../api/shipment"

const state = {
  status: null,
  hasLoadedOnce: null,
  shipmentList: [],
  cancellableShipmentList: [],
  availableShipmentStatus: [
    {
      text: 'New',
      value: "SHIPMENT_NEW",
      color: '#1F1F24'
    },
    {
      text: 'Depart',
      value: "SHIPMENT_DEPART",
      color: '#E23420'
    },
    {
      text: 'Arrive',
      value: "SHIPMENT_ARRIVE",
      color: '#F2C341'
    },
    {
      text: 'Update',
      value: "SHIPMENT_UPDATE",
      color: '#EEA93A'
    },
    {
      text: 'Complete',
      value: "SHIPMENT_COMPLETE",
      color: '#6DC860'
    },
    {
      text: 'Cancel',
      value: "SHIPMENT_CANCEL",
      color: '#545557'
    },
  ],
};

const getters = {
  getShipmentListing: state => cloneDeep(state.shipmentList),
  getShipmentDetail: state => arg => cloneDeep(state.shipmentList.find(el => el.no == arg)),
  getAvailableShipmentStatus: state => cloneDeep(state.availableShipmentStatus),
  getCancellableShipmentListing: state => cloneDeep(state.cancellableShipmentList),
};

const actions = {
  [shipmentActions.SHIPMENT_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(shipmentActions.SHIPMENT_LIST_REQUEST);
      shipmentApis.getShipmentList(postData)
      .then(resp => {
        commit(shipmentActions.SHIPMENT_LIST_SUCCESS, resp);
        resolve(resp);
      })
      .catch(err => {
        commit(shipmentActions.SHIPMENT_LIST_ERROR);
        reject(err);
      });
    })
  },
  [shipmentActions.SHIPMENT_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(shipmentActions.SHIPMENT_REQUEST);
      shipmentApis.getShipment(postData)
      .then(resp => {
        if (resp.status != 'ok') throw resp
        commit(shipmentActions.SHIPMENT_SUCCESS, resp);
        resolve(resp);
      })
      .catch(err => {
        commit(shipmentActions.SHIPMENT_ERROR);
        reject(err);
      });
    })
  },
  [shipmentActions.SHIPMENT_CANCEL_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(shipmentActions.SHIPMENT_CANCEL_REQUEST);
      shipmentApis.cancelShipment(postData)
      .then(resp => {
        if (resp.status != 'ok') throw resp
        commit(shipmentActions.SHIPMENT_CANCEL_SUCCESS, resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err)
        commit(shipmentActions.SHIPMENT_CANCEL_ERROR);
        reject(err);
      });
    })
  },
  [shipmentActions.CANCELLABLE_SHIPMENT_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(shipmentActions.CANCELLABLE_SHIPMENT_LIST_REQUEST);
      shipmentApis.getCancellableShipmentList(postData)
      .then(resp => {
        commit(shipmentActions.CANCELLABLE_SHIPMENT_LIST_SUCCESS, resp);
        resolve(resp);
      })
      .catch(err => {
        commit(shipmentActions.CANCELLABLE_SHIPMENT_LIST_ERROR);
        reject(err);
      });
    })
  },
  [shipmentActions.SHIPMENT_BULK_CANCEL_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(shipmentActions.SHIPMENT_BULK_CANCEL_REQUEST);
      shipmentApis.bulkCancelShipments(postData)
      .then(resp => {
        commit(shipmentActions.SHIPMENT_BULK_CANCEL_SUCCESS, resp);
        resolve(resp);
      })
      .catch(err => {
        commit(shipmentActions.SHIPMENT_BULK_CANCEL_ERROR);
        reject(err);
      });
    })
  },
};

const mutations = {
  SHIPMENT_LIST_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  SHIPMENT_LIST_SUCCESS: (state, postData) => {
    Object.assign(state, {
      shipmentList: postData,
      hasLoadedOnce: true
    });
  },
  SHIPMENT_LIST_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      shipmentList: [],
      hasLoadedOnce: true
    });
  },

  SHIPMENT_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  SHIPMENT_SUCCESS: (state) => {
    Object.assign(state, {
      hasLoadedOnce: true
    });
  },
  SHIPMENT_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },

  SHIPMENT_CANCEL_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  SHIPMENT_CANCEL_SUCCESS: (state) => {
    Object.assign(state, {
      hasLoadedOnce: true
    });
  },
  SHIPMENT_CANCEL_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },

  CANCELLABLE_SHIPMENT_LIST_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  CANCELLABLE_SHIPMENT_LIST_SUCCESS: (state, postData) => {
    Object.assign(state, {
      cancellableShipmentList: postData,
      hasLoadedOnce: true
    });
  },
  CANCELLABLE_SHIPMENT_LIST_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      cancellableShipmentList: [],
      hasLoadedOnce: true
    });
  },

  SHIPMENT_BULK_CANCEL_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  SHIPMENT_BULK_CANCEL_SUCCESS: (state) => {
    Object.assign(state, {
      hasLoadedOnce: true
    });
  },
  SHIPMENT_BULK_CANCEL_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
