import * as authActions from "./actions";
import * as authApis from "@/api/auth";

let userData = JSON.parse(localStorage.getItem("user")) || {};
let userRole = "";
if (userData) {
  userRole = userData.role;
}

const state = {
  token: localStorage.getItem("user-token") || "",
  userWithInfos: {
    ...userData,
    isAdmin: userRole == 'ROLE_ADMIN',
    isDriver: userRole == 'ROLE_DRIVER',
    isManager: userRole == 'ROLE_MANAGER',
    isLogisticsOfficer: userRole == 'ROLE_LOGISTICS_OFFICER',
  },
  status: "",
  hasLoadedOnce: false,
};

const getters = {
  // isAuthenticated: state => !!state.token,
  isAuthenticated: state => state.userWithInfos.uuid,
  authStatus: state => state.status,
  getUser: state => state.userWithInfos,
};

const actions = {
  [authActions.AUTH_REQUEST]: ({ commit }, authData) => {
    return new Promise((resolve, reject) => {
      authApis.loginByUsername(authData)
      .then(resp => {
        if (resp) {
          let isPassed = resp.status == 'ok';
          if (isPassed) {
            // localStorage.setItem("user-token", ad.accessToken);
            let profile = resp.response;
            if (profile) {
              localStorage.setItem("user", JSON.stringify(profile));
              commit(authActions.AUTH_SUCCESS, profile);
              resolve(resp);
            } else {
              commit(authActions.AUTH_ERROR, resp);
              // localStorage.removeItem("user-token");
              localStorage.removeItem("user");
              reject(resp);
            }
          }
        }
      })
      .catch(err => {
        commit(authActions.AUTH_ERROR, err);
        // localStorage.removeItem("user-token");
        localStorage.removeItem("user");
        reject(err);
      });
    });
  },
  [authActions.AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      commit(authActions.AUTH_LOGOUT);
      // localStorage.removeItem("user-token");
      localStorage.removeItem("user");
      resolve({
        logout: true
      });
    });
  },
  [authActions.PROFILE_POST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(authActions.PROFILE_POST_REQUEST);
      authApis.updateProfile(postData)
        .then(resp => {
          commit(authActions.PROFILE_POST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(authActions.PROFILE_POST_ERROR, err);
          reject(err);
        });
    });
  },
  [authActions.PASSWORD_POST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(authActions.PASSWORD_POST_REQUEST);
      authApis.updatePassword(postData)
        .then(resp => {
          commit(authActions.PASSWORD_POST_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(authActions.PASSWORD_POST_ERROR, err);
          reject(err);
        });
    });
  },
  [authActions.PASSWORD_RESET_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(authActions.PASSWORD_RESET_REQUEST);
      authApis.resetByEmail(postData)
        .then(resp => {
          commit(authActions.PASSWORD_RESET_SUCCESS, resp);
          resolve(resp);
        })
        .catch(err => {
          commit(authActions.PASSWORD_RESET_ERROR, err);
          reject(err);
        });
    });
  }
};

const mutations = {
  AUTH_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  AUTH_SUCCESS: (state, data) => {
    Object.assign(state, {
      status: "",
      // token: token,
      hasLoadedOnce: true
    });
    Object.assign(state.userWithInfos, {...data})
  },
  AUTH_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  AUTH_LOGOUT: state => {
    Object.assign(state, {
      status: "loading",
      hasLoadedOnce: true
    });
  },
  SET_USER_TOKEN: (state, token) => {
    Object.assign(state, {
      token
    });
  },
  PROFILE_POST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PROFILE_POST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  PROFILE_POST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  PASSWORD_POST_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PASSWORD_POST_SUCCESS: state => {
    Object.assign(state, {
      state: "",
      hasLoadedOnce: true
    });
  },
  PASSWORD_POST_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
  PASSWORD_RESET_REQUEST: state => {
    Object.assign(state, {
      status: "loading"
    });
  },
  PASSWORD_RESET_SUCCESS: state => {
    Object.assign(state, {
      status: "",
      hasLoadedOnce: true
    });
  },
  PASSWORD_RESET_ERROR: state => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
