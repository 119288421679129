import request from "@/utils/request";
import { DEFAULT_TIMEOUT } from "../constants/index";
import * as apiConstants from "../constants/services";

export async function getUserList(data) {
  return await request({
    url: apiConstants.API_GET_USER_LIST,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: data,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getUser(data) {
  return await request({
    url: apiConstants.API_USER_PROFILE,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: data,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function postUser(data) {
  return await request({
    url: apiConstants.API_UPDATE_USER_PROFILE,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: data,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function addUser(data) {
  return await request({
    url: apiConstants.API_ADD_USER_PROFILE,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: data,
    timeout: DEFAULT_TIMEOUT
  });
}