import cloneDeep from "lodash/cloneDeep";

import * as userActions from "./actions";
import * as userApis from "../../../api/user"

import { availableUserRoles, availableUserStatus } from "../../../constants/all-lists"

const state = {
  status: null,
  hasLoadedOnce: null,
  userList: [],
  user: null,
  availableUserRoles: availableUserRoles,
  availableUserStatus: availableUserStatus,
};

const getters = {
  getUserListing: state => isDriverList => {
    return cloneDeep(state.userList.filter(el => {
      if (isDriverList) return el.role === "ROLE_DRIVER"
      else return el.role !== 'ROLE_DRIVER'
    }))
  },
  getUserDetail: state => arg => cloneDeep(state.userList.find(el => el.id == arg)),

  getAvailableUserRoles: state => cloneDeep(state.availableUserRoles),
  getAvailableUserStatus: state => cloneDeep(state.availableUserStatus),
  parseUserRole: state => arg => cloneDeep(state.availableUserRoles.find(el => el.value == arg).text),
  parseUserStatus: state => arg => cloneDeep(state.availableUserStatus.find(el => el.value == arg).text),
};

const actions = {
  [userActions.USER_PROFILE_LIST_REQUEST]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit(userActions.USER_PROFILE_LIST_REQUEST);
      userApis.getUserList(data)
      .then(resp => {
        commit(userActions.USER_PROFILE_LIST_SUCCESS, resp);
        resolve(resp);
      })
      .catch(err => {
        commit(userActions.USER_PROFILE_LIST_ERROR);
        reject(err);
      });
    })
  },
  [userActions.USER_PROFILE_REQUEST]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit(userActions.USER_PROFILE_REQUEST);
      userApis.getUser(data)
      .then(resp => {
        commit(userActions.USER_PROFILE_SUCCESS, resp);
        resolve(resp);
      })
      .catch(err => {
        commit(userActions.USER_PROFILE_ERROR);
        reject(err);
      });
    })
  },
  [userActions.USER_PROFILE_POST_REQUEST]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit(userActions.USER_PROFILE_POST_REQUEST);
      userApis.postUser(data)
      .then(resp => {
        commit(userActions.USER_PROFILE_POST_REQUEST, resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err)
        commit(userActions.USER_PROFILE_POST_ERROR);
        reject(err);
      });
    })
  },
  [userActions.USER_PROFILE_ADD_REQUEST]: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      commit(userActions.USER_PROFILE_ADD_REQUEST);
      userApis.addUser(data)
      .then(resp => {
        commit(userActions.USER_PROFILE_ADD_REQUEST, resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err)
        commit(userActions.USER_PROFILE_ADD_ERROR);
        reject(err);
      });
    })
  },
};

const mutations = {
  USER_PROFILE_LIST_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  USER_PROFILE_LIST_SUCCESS: (state, postData) => {
    Object.assign(state, {
      status: '',
      userList: postData.response
    });
  },
  USER_PROFILE_LIST_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      userList: [],
      hasLoadedOnce: true
    });
  },

  USER_PROFILE_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  USER_PROFILE_SUCCESS: (state, postData) => {
    Object.assign(state, {
      status: '',
      user: postData.response
    });
  },
  USER_PROFILE_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      user: null,
      hasLoadedOnce: true
    });
  },

  USER_PROFILE_POST_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  USER_PROFILE_POST_SUCCESS: (state) => {
    Object.assign(state, {
      status: '',
    });
  },
  USER_PROFILE_POST_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true,
    });
  },

  USER_PROFILE_ADD_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  USER_PROFILE_ADD_SUCCESS: (state) => {
    Object.assign(state, {
      status: '',
    });
  },
  USER_PROFILE_ADD_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true,
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
