// import app from "../main";
import axios from "axios";
import store from "@/store";
import { API_URL } from "@/constants";
import { EventBus } from "@/utils/EventBus";

import { NOTIFICATION_ERROR } from "../store/modules/_shared/ui/actions";
import { AUTH_LOGOUT } from "../store/modules/auth/actions";
// eslint-disable-next-line no-unused-vars
// create an axios instance
const service = axios.create({
  baseURL: API_URL
});

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// request interceptor
service.interceptors.request.use(
  serviceConfig => {
    serviceConfig.data.uuid = store.getters.getUser.uuid
    if (process.env.NODE_ENV !== "production") {
      console.log("Sending to API", serviceConfig.data)
    }
    // app.$Progress.start();
    
    // serviceConfig.headers.common["Accept"] = "application/vnd.globalline.lrcm-v1+json";
    serviceConfig.headers.common["Content-Type"] = "application/json";
    // serviceConfig.headers.common["X-Requested-With"] = "XMLHttpRequest";
    // serviceConfig.headers.common['Access-Control-Allow-Origin'] = '*';
    // serviceConfig.headers.common['Access-Control-Allow-Credentials'] = true;
    // serviceConfig.headers.common['crossorigin'] = true;
    // serviceConfig.headers.common['credentials'] = 'same-origin';
    // serviceConfig.headers.common['mode'] = 'no-cors';

    let token = localStorage.getItem("user-token");
    if (token) {
      serviceConfig.headers.common["Authorization"] = "Bearer " + token;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }

    return serviceConfig;
  },
  error => {
    // app.$Progress.fail();
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response;
    if (process.env.NODE_ENV !== "production") {
      console.log("Got back from API", res)
    }
    if (res.status === 200) {
      // app.$Progress.finish();
      if (res.data.errorCode == 'security.invalid.login') {
        EventBus.$emit("duplicateLogin", res.data.errorMessage);
        setTimeout(() => store.dispatch(AUTH_LOGOUT).then(res => {
          if (res.logout) {
            EventBus.$emit("userLogout", 1);
          }
        }), 3000);
        return res.data
      }
      return res.data;
    } else if (res.status !== 200) {
      // app.$Progress.finish();
      // store.dispatch(NOTIFICATION_CLOSE, {
      //   message: res.message
      // });
      store.dispatch(NOTIFICATION_ERROR, {
        message: res.message
      });
      return res.data;
    } else if (res.data.resultCode === -8) {
      // app.$Progress.finish();
      // store.dispatch(NOTIFICATION_CLOSE, {
      //   message: res.message
      // });
      store.dispatch(NOTIFICATION_ERROR, {
        message: res.data.content.message
      });
      return res.data;
    } else if (res.data.resultCode <= -1) {
      store.dispatch(NOTIFICATION_ERROR, {
        message: res.data.content.message
      });
      return res.data;
    }
    // app.$Progress.finish();
  },
  error => {
    // document.body.classList.remove("loading-indicator");
    // $("#preloader").fadeOut(500);
    // app.$Progress.fail();
    // store.dispatch(NOTIFICATION_CLOSE, {
    //   message: res.message
    // });
    store.dispatch(NOTIFICATION_ERROR, {
      message: error.errorMessage
    });
  }
);

export default service;
