export const INTERNAL_NOTIFICATIONS_REQUEST = "INTERNAL_NOTIFICATIONS_REQUEST";
export const INTERNAL_NOTIFICATIONS_SUCCESS = "INTERNAL_NOTIFICATIONS_SUCCESS";
export const INTERNAL_NOTIFICATIONS_ERROR = "INTERNAL_NOTIFICATIONS_ERROR";

export const INTERNAL_NOTIFICATIONS_POST_REQUEST = "INTERNAL_NOTIFICATIONS_POST_REQUEST";
export const INTERNAL_NOTIFICATIONS_POST_SUCCESS = "INTERNAL_NOTIFICATIONS_POST_SUCCESS";
export const INTERNAL_NOTIFICATIONS_POST_ERROR = "INTERNAL_NOTIFICATIONS_POST_ERROR";

export const CUSTOMER_LIST_REQUEST = "CUSTOMER_LIST_REQUEST";
export const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";
export const CUSTOMER_LIST_ERROR = "CUSTOMER_LIST_ERROR";

export const CUSTOMER_POST_REQUEST = "CUSTOMER_POST_REQUEST";
export const CUSTOMER_POST_SUCCESS = "CUSTOMER_POST_SUCCESS";
export const CUSTOMER_POST_ERROR = "CUSTOMER_POST_ERROR";