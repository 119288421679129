import cloneDeep from "lodash/cloneDeep"
import * as notificationActions from "./actions";

import * as allApis from "../../../api/notification"

const state = {
  status: null,
  hasLoadedOnce: null,
  internalNotifications: [],
  customerList: [],
};

const getters = {
  getInternalNotifications: state => cloneDeep(state.internalNotifications),
  getCustomerListing: state => cloneDeep(state.customerList),
  getCustomerDetail: state => arg => cloneDeep(state.customerList.find(el => el.id == arg)),
};

const actions = {
  [notificationActions.INTERNAL_NOTIFICATIONS_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(notificationActions.INTERNAL_NOTIFICATIONS_REQUEST);
      allApis.getInternalNotifications(postData)
      .then(resp => {
        if (resp) {
          commit(notificationActions.INTERNAL_NOTIFICATIONS_SUCCESS, resp);
          resolve(resp);
        } else {
          throw(resp)
        }
      })
      .catch(err => {
        console.log(err)
        commit(notificationActions.INTERNAL_NOTIFICATIONS_ERROR);
        reject(err);
      });
    })
  },
  [notificationActions.INTERNAL_NOTIFICATIONS_POST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(notificationActions.INTERNAL_NOTIFICATIONS_POST_REQUEST);
      allApis.postInternalNotifications(postData)
      .then(resp => {
        if (resp) {
          commit(notificationActions.INTERNAL_NOTIFICATIONS_POST_SUCCESS, resp);
          resolve(resp);
        } else {
          throw(resp)
        }
      })
      .catch(err => {
        console.log(err)
        commit(notificationActions.INTERNAL_NOTIFICATIONS_POST_ERROR);
        reject(err);
      });
    })
  },
  [notificationActions.CUSTOMER_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(notificationActions.CUSTOMER_LIST_REQUEST);
      allApis.getCustomerList(postData)
      .then(resp => {
        if (resp.status == 'ok') {
          commit(notificationActions.CUSTOMER_LIST_SUCCESS, resp.response);
          resolve(resp);
        } else {
          commit(notificationActions.CUSTOMER_LIST_ERROR);
          reject(resp);
        }
      })
      .catch(err => {
        commit(notificationActions.CUSTOMER_LIST_ERROR);
        reject(err);
      });
    })
  },
  [notificationActions.CUSTOMER_POST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(notificationActions.CUSTOMER_LIST_REQUEST);
      allApis.postCustomer(postData)
      .then(resp => {
          commit(notificationActions.CUSTOMER_POST_SUCCESS, resp.response);
          resolve(resp);
      })
      .catch(err => {
        commit(notificationActions.CUSTOMER_POST_ERROR);
        reject(err);
      });
    })
  },
};

const mutations = {
  INTERNAL_NOTIFICATIONS_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  INTERNAL_NOTIFICATIONS_SUCCESS: (state, postData) => {
    Object.assign(state, {
      internalNotifications: postData,
      hasLoadedOnce: true,
    });
  },
  INTERNAL_NOTIFICATIONS_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      internalNotifications: [],
      hasLoadedOnce: true
    });
  },

  INTERNAL_NOTIFICATIONS_POST_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  INTERNAL_NOTIFICATIONS_POST_SUCCESS: (state, postData) => {
    Object.assign(state, {
      hasLoadedOnce: true,
      internalNotifications: postData,
    });
  },
  INTERNAL_NOTIFICATIONS_POST_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },

  CUSTOMER_LIST_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  CUSTOMER_LIST_SUCCESS: (state, postData) => {
    Object.assign(state, {
      customerList: postData,
      hasLoadedOnce: true,
    });
  },
  CUSTOMER_LIST_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      customerList: [],
      hasLoadedOnce: true
    });
  },

  CUSTOMER_POST_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  CUSTOMER_POST_SUCCESS: (state) => {
    Object.assign(state, {
      hasLoadedOnce: true,
    });
  },
  CUSTOMER_POST_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
