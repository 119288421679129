export const USER_PROFILE_LIST_REQUEST = "USER_PROFILE_LIST_REQUEST";
export const USER_PROFILE_LIST_SUCCESS = "USER_PROFILE_LIST_SUCCESS";
export const USER_PROFILE_LIST_ERROR = "USER_PROFILE_LIST_ERROR";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_ERROR = "USER_PROFILE_ERROR";

export const USER_PROFILE_POST_REQUEST = "USER_PROFILE_POST_REQUEST";
export const USER_PROFILE_POST_SUCCESS = "USER_PROFILE_POST_SUCCESS";
export const USER_PROFILE_POST_ERROR = "USER_PROFILE_POST_ERROR";

export const USER_PROFILE_ADD_REQUEST = "USER_PROFILE_ADD_REQUEST";
export const USER_PROFILE_ADD_SUCCESS = "USER_PROFILE_ADD_SUCCESS";
export const USER_PROFILE_ADD_ERROR = "USER_PROFILE_ADD_ERROR";
