<template>
  <sidebar-menu 
    :menu="menu" 
    :collapsed="collapsed" 
    width="25rem"
    :hideToggle="true"
    @item-click="onItemClick"
    @toggle-collapse="onCollapse">
      <div slot="header" class="vsm--header d-flex justify-content-center my-4">
        <router-link to="/"><div class="custom-icon logo-icon"></div></router-link>
      </div>
  </sidebar-menu>
</template>

<script>
  export default {
    name: 'Sidebar',
    props: ['collapsed', 'onCollapseAction'],
    data() {
      return {
        isSyncing: false,
        currentUser: this.$store.getters.getUser,
        menu: [
          {
            href: '/dashboard',
            title: 'Dashboard',
            icon: 'custom-icon dashboard-icon',
          },
          {
            href: '/user-management',
            title: 'User Management',
            icon: 'custom-icon user-management-icon',
            allow: ["ROLE_ADMIN", 'ROLE_MANAGER', "ROLE_LOGISTICS_OFFICER"],
          },
          {
            href: '/shipment-management',
            title: 'Shipment Management',
            icon: 'custom-icon shipment-management-icon',
          },
          {
            href: '/vehicle-management',
            title: 'Vehicle Management',
            icon: 'custom-icon vehicle-management-icon',
          },
          {
            href: '/notification-management',
            title: 'Notification Management',
            icon: 'custom-icon notification-management-icon',
          }
        ],
        // path: this.$route.path
      }
    },
    methods: {
      onCollapse () {
        this.onCollapseAction();
      },
      onItemClick(event, item){
        this.menu = this.menu.map(el => 
          {
            var temp = Object.assign({}, el);
            let element = document.getElementsByClassName(el.icon)[0];
            if(element) element.classList.remove('is-active');
            if(el !== item) {
              temp.icon = temp.icon.replace('is-active','');
            } else {
              temp.icon = temp.icon + " is-active";
            }
            return temp;
          }
        );
      },
      filterMenu() {
        this.menu = this.menu.filter(el => {      
            if (el.allow) {
              let found = el.allow.filter(role => this.currentUser.role == role)
              return found.length > 0
            } else {
              return true
            }
        })
      },
      observeSidebar(){
        const target = document.getElementsByClassName('vsm--link');
        for (var i = 0; i < target.length; i++) {
          var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
              if(mutation.target.classList.contains('router-link-active')){
                for (var i = 0; i < mutation.target.childNodes.length; i++) {
                  if (mutation.target.childNodes[i].classList && mutation.target.childNodes[i].classList.contains('vsm--icon')) {
                    mutation.target.childNodes[i].classList.add('is-active');
                  }
                }
              } else {
                for (var k = 0; k < mutation.target.childNodes.length; k++) {
                  if (mutation.target.childNodes[k].classList && mutation.target.childNodes[k].classList.contains('vsm--icon')) {
                    mutation.target.childNodes[k].classList.remove('is-active');
                  }
                }
              }
            });
          });
          var config = { attributes: true };
          observer.observe(target[i], config);
        }
      }
    },
    mounted(){
      this.filterMenu()
      this.menu = this.menu.map(el => {
        var temp = Object.assign({}, el);
        if(this.$route.path.includes(el.href.split("/").slice(0,2).join("/"))) temp.icon = temp.icon + " is-active";
        return temp;
      });
      this.observeSidebar();
    }
  }
</script>
<style scoped>
.menu-toggle .vsm--footer p{
  display: none;
}
.vsm--footer p{
  display: block;
}
.menu-toggle .logo-icon{
  width: 35px;
}
</style>