<script>
import { mapGetters } from "vuex";
import Header from "../components/header/header.vue";
import Footer from "../components/footer/footer.vue";
import Sidebar from "../components/sidebar/sidebar.vue";
import { EventBus } from "@/utils/EventBus";
export default {
  name: "Admin",
  components: {
    "admin-header": Header,
    "admin-footer": Footer,
    "admin-sidebar": Sidebar
  },
  computed: {
    ...mapGetters(["getProfile", "isAuthenticated", "isProfileLoaded", "getUser"]),
  },
  data() {
    return {
      collapsed: false,
      duplicateLoginMsg: "",
      countdown: 0,
      intervalId: null,
    };
  },
  watch: {
    // getUser: {
    //   handler: function() {},
    //   deep: true
    // }
  },
  methods: {
    onCollapse(){
      this.collapsed = !this.collapsed;
    }
  },
  mounted() {
    EventBus.$on("duplicateLogin", (msg) => {
      this.duplicateLoginMsg = msg
      this.countdown = 3
      this.intervalId = setInterval(() => {
        this.countdown--;
      }, 1000);
    });

    EventBus.$on("userLogout", () => {
      this.$router.push("/sign-in");
      location.reload();
    });

    EventBus.$on("reRenderApp", data => {
      let userData = JSON.parse(localStorage.getItem("user")) || {};
      let userId = userData.user ? userData.user.id : 0;

      if (userId != this.user.userId) {
        location.reload();
      }
      this.reRenderComponent = data;
    });

    EventBus.$on("toggleSideMenu", (type) => {
      if (type == 'hide') this.collapsed = true
      else this.onCollapse()
    })
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  }
};
</script>

<template>
  <div>
    <div v-if="duplicateLoginMsg != ''" id="blocking" class="d-flex align-content-center">
      <h1 class="m-auto text-center">{{ duplicateLoginMsg }}<br/>Logging out in {{ countdown }} seconds.</h1>
    </div>
    <div id="main-wrapper" class="main-component-wrapper" :class="[{'menu-toggle' : collapsed}]">
      <img src="/assets/bg_deco.png" alt="background">
      <admin-header :toggleSidebarAction="onCollapse"/>
      <admin-sidebar :collapsed="collapsed" :onCollapseAction="onCollapse"/>
      <div class="main-container">
        <div class="content-body main-container">
          <div class="container-fluid admin-layout-body">
            <!-- <router-view></router-view> -->
            <slot />
          </div>
          <admin-footer />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#blocking {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: grey;
  z-index: 10000;
  h1 {
    padding: 3rem;
    background-color: white;
  }
}
</style>