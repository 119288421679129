
// USER
export const availableUserRoles = [
  {
    text: 'Admin',
    value: 'ROLE_ADMIN'
  },
  {
    text: 'Manager',
    value: 'ROLE_MANAGER'
  },
  {
    text: 'Driver',
    value: 'ROLE_DRIVER'
  },
  {
    text: 'Logistics Officer',
    value: 'ROLE_LOGISTICS_OFFICER'
  },
]
export const availableUserStatus = [
  {
    text: 'Active',
    value: 'ACTIVE'
  },
  {
    text: 'Inactive',
    value: 'INACTIVE'
  },
]