import request from "@/utils/request";
import { DEFAULT_TIMEOUT } from "../constants/index";
import * as apiConstants from "../constants/services";


export async function getVehicleList() {
  return await request({
    url: apiConstants.API_GET_VEHICLE_LIST,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: {},
    timeout: DEFAULT_TIMEOUT
  });
}