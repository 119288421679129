import * as dashboardActions from "./actions";

import * as dashboardApis from "../../../api/dashboard"

const state = {
  status: null,
  hasLoadedOnce: null,
  dashboardList: [],
};

const getters = {
};

const actions = {
  [dashboardActions.DASHBOARD_LIST_REQUEST]: ({ commit }, postData) => {
    return new Promise((resolve, reject) => {
      commit(dashboardActions.DASHBOARD_LIST_REQUEST);
      dashboardApis.getDashboardList(postData)
      .then(resp => {
        if (resp.status != 'ok') throw(resp)
        commit(dashboardActions.DASHBOARD_LIST_SUCCESS, resp);
        resolve(resp);
      })
      .catch(err => {
        commit(dashboardActions.DASHBOARD_LIST_ERROR);
        reject(err);
      });
    })
  },
};

const mutations = {
  DASHBOARD_LIST_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  DASHBOARD_LIST_SUCCESS: (state, postData) => {
    Object.assign(state, {
      dashboardList: postData
    });
  },
  DASHBOARD_LIST_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      dashboardList: [],
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
