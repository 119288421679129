import { ifAuthenticatedAndAllowed } from "../index"

// import {
//   VehicleManagementList,
//   PassThrough,
// } from "../../constants/component-imports"

import {
  LAYOUT_ADMIN
} from "../../constants";

const vehicleManagementRouter = {
  path: "/vehicle-management",
  component: () => import("@/views/pass-through.vue"),
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_LOGISTICS_OFFICER'
    ],
  },
  children: [
    {
      path: "",
      name: "",
      component: () => import("@/views/vehicle-management/list.vue"),
      beforeEnter: (to, from, next) => ifAuthenticatedAndAllowed(to, from, next),
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_LOGISTICS_OFFICER'
        ],
        title: "Vehicle Management",
        subtitles: []
      },
    },
  ],
  beforeEnter: (to, from, next) => ifAuthenticatedAndAllowed(to, from, next)
};

export default vehicleManagementRouter;