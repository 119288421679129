// Auth
export const API_LOGIN_SERVICE = "/spci/user/login/web";
export const API_FORGOT_PW_SERVICE = "/spci/user/password/reset/web";

//User
export const API_GET_USER_LIST = "/spci/user/all";
export const API_USER_PROFILE = "/spci/user/id";
export const API_UPDATE_USER_PROFILE = "/spci/user/update";
export const API_ADD_USER_PROFILE = "/spci/user/add";
export const API_UPDATE_USER_PASSWORD = "/spci/user/password/update";

//Dashboard
export const API_GET_DASHBOARD_LIST = "/spci/dashboard/data";

//Shipment
export const API_GET_SHIPMENT_LIST = "/spci/shipment/list";
export const API_GET_SHIPMENT = "/spci/shipment/id";
export const API_CANCEL_SHIPMENT = "/spci/shipment/update/cancel";
export const API_CANCELLABLE_SHIPMENT_LIST = "/spci/shipment/cancellable-list";
export const API_BULK_CANCEL_SHIPMENT = "/spci/shipment/update/bulk-cancel";

//Vehicle
export const API_GET_VEHICLE_LIST = "/spci/vehicle/all";

//Notification
export const API_GET_INTERNAL_NOTIFICATIONS = "/spci/notification/all/internal-notification";
export const API_POST_INTERNAL_NOTIFICATIONS = "/spci/notification/update/internal-notification";
export const API_GET_CUSTOMER_LIST = "/spci/notification/all/customer-notification";
export const API_POST_CUSTOMER = "/spci/notification/update/customer-notification";
