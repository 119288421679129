import request from "@/utils/request";
import { DEFAULT_TIMEOUT } from "../constants/index";
import * as apiConstants from "../constants/services";

export async function getShipmentList(postData) {
  return await request({
    url: apiConstants.API_GET_SHIPMENT_LIST,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getShipment(postData) {
  return await request({
    url: apiConstants.API_GET_SHIPMENT,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}


export async function cancelShipment(postData) {
  return await request({
    url: apiConstants.API_CANCEL_SHIPMENT,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function getCancellableShipmentList(postData) {
  return await request({
    url: apiConstants.API_CANCELLABLE_SHIPMENT_LIST,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function bulkCancelShipments(postData) {
  return await request({
    url: apiConstants.API_BULK_CANCEL_SHIPMENT,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}