import { ifAuthenticatedAndAllowed } from "../index"

// import {
//   ShipmentManagementList, ShipmentManagementDetail,
//   PassThrough,
// } from "../../constants/component-imports"

import {
  LAYOUT_ADMIN
} from "../../constants";

const shipmentManagementRouter = {
  path: "/shipment-management",
  component: () => import("@/views/pass-through.vue"),
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_LOGISTICS_OFFICER'
    ],
  },
  children: [
    {
      path: "",
      name: "",
      component: () => import("@/views/shipment-management/list.vue"),
      beforeEnter: (to, from, next) => ifAuthenticatedAndAllowed(to, from, next),
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_LOGISTICS_OFFICER'
        ],
        title: "Shipment Management",
        subtitles: []
      },
    },
    {
      path: "detail/:shipmentId",
      name: "", //Will inject programmatically with Sales Shipment No
      component: () => import("@/views/shipment-management/detail.vue"),
      beforeEnter: (to, from, next) => ifAuthenticatedAndAllowed(to, from, next),
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_LOGISTICS_OFFICER'
        ],
        title: "Shipment Management",
        subtitles: []
      },
    },
    
  ],
  beforeEnter: (to, from, next) => ifAuthenticatedAndAllowed(to, from, next)
};

export default shipmentManagementRouter;