import request from "@/utils/request";
import { DEFAULT_TIMEOUT } from "../constants/index";

import * as apiConstants from "../constants/services";

export async function getDashboardList(postData) {
  return await request({
    url: apiConstants.API_GET_DASHBOARD_LIST,
    method: "post",
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    },
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}