import Vue from "vue";
import Router from "vue-router";
import $ from "jquery";

import userManagementRouter from "./modules/user-management";
import vehicleManagementRouter from "./modules/vehicle-management";
import shipmentManagementRouter from "./modules/shipment-management";
import notificationManagementRouter from "./modules/notification-management";

// import {
//   Dashboard, DeniedPage, SignIn,
//   Profile,
// } from "../constants/component-imports"

import store from "../store";
import {
  LAYOUT_ADMIN,
  LAYOUT_AUTH
} from "../constants";

Vue.use(Router);

//only allow pass if not authenticated
const ifNotAuthenticated = (to, from, next) => {
  jQueryInit();
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/dashboard");
};

//only allow pass if authenticated
const ifAuthenticated = (to, from, next) => {
  jQueryInit();
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/sign-in");
};

//only allow pass if authenticated and allowed by user role
export function ifAuthenticatedAndAllowed(to, from, next) {
  if (store.getters.isAuthenticated) {
    let user = JSON.parse(localStorage.getItem("user"));
    let allowed = 0;
    for (let i = 0; i < to.meta.permission.length; i++) {
      if (to.meta.permission[i] === user.role) {
        allowed = 1;
        next();
        return;
      }
    }
    if (allowed === 0) {
      next("/not-allowed");
      return;
    }
  }
  next("/sign-in");
}

function jQueryInit() {
  $(window).on("load", function() {
    $("#preloader").fadeOut(500);
    $("#main-wrapper").addClass("show");

    $("body").attr("data-sidebar-style") === "mini"
      ? $(".hamburger").addClass("is-active")
      : $(".hamburger").removeClass("is-active");
    if($(".hamburger").hasClass("is-active")){
      $(".brand-logo-wrapper").css("margin-left", "0rem");
    } else {
      $(".brand-logo-wrapper").css("margin-left", `-${$(".hamburger").width()/2}px`);
    }
  });
}

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/dashboard",
      component: () => import('@/views/dashboard.vue'),
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          'ROLE_MANAGER', 'ROLE_LOGISTICS_OFFICER'
        ],
        name: "",
        title: "Dashboard",
        subtitles: []
      },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/not-allowed",
      component: () => import("@/views/tmpls/denied-page.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_LOGISTICS_OFFICER'
        ]
      }
    },
    {
      path: "/profile",
      name: "Profile",
      component: () => import("@/views/auth/profile.vue"),
      beforeEnter: ifAuthenticated,
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          'ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_LOGISTICS_OFFICER'
        ]
      }
    },
    {
      path: "/sign-in",
      component: () => import("@/views/auth/sign-in.vue"),
      meta: {
        layout: LAYOUT_AUTH
      },
      beforeEnter: ifNotAuthenticated
    },
    userManagementRouter,
    vehicleManagementRouter,
    shipmentManagementRouter,
    notificationManagementRouter,
    {
      path: '*',
      redirect: '/sign-in',
      meta: {
        rule: 'isPublic',
        layout: LAYOUT_AUTH
      },
      component: () => import("@/views/tmpls/denied-page.vue"),
    },
  ]
});