import * as uiActions from "./actions";

const state = {
  statusNotification: {
    message: "",
    type: "",
    indefinite: true,
    show: false,
    timer: 5000
  },
};

const getters = {
  statusNotification: state => state.statusNotification,
};

const actions = {
  [uiActions.NOTIFICATION_REQUEST]: ({ commit }, postData) => {
    commit(uiActions.NOTIFICATION_REQUEST, postData);
  },
  [uiActions.NOTIFICATION_SUCCESS]: ({ commit }, postData) => {
    commit(uiActions.NOTIFICATION_SUCCESS, postData);
  },
  [uiActions.NOTIFICATION_ERROR]: ({ commit }, postData) => {
    commit(uiActions.NOTIFICATION_ERROR, postData);
  },
  [uiActions.NOTIFICATION_CLOSE]: ({ commit }) => {
    commit(uiActions.NOTIFICATION_CLOSE);
  },
};

const mutations = {
  NOTIFICATION_REQUEST: (state, postData) => {
    state.statusNotification.show = true;
    state.statusNotification.isLoading = true;
    state.statusNotification.message = postData.message;
  },
  NOTIFICATION_SUCCESS: (state, postData) => {
    state.statusNotification.show = true;
    state.statusNotification.isLoading = false;
    state.statusNotification.indefinite = false;
    state.statusNotification.type = "is-success";
    state.statusNotification.message = postData.message;
    state.statusNotification.timer = 5000;
  },
  NOTIFICATION_ERROR: (state, postData) => {
    state.statusNotification.show = true;
    state.statusNotification.isLoading = false;
    state.statusNotification.indefinite = false;
    state.statusNotification.type = "is-danger";
    state.statusNotification.message = postData.message;
    state.statusNotification.timer = 5000;
  },
  NOTIFICATION_CLOSE: state => {
    state.statusNotification.show = false;
    state.statusNotification.indefinite = true;
    state.statusNotification.type = "";
    state.statusNotification.message = "";
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
