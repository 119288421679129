import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth/index";
import dashboard from "./modules/dashboard/index";
import ui from "./modules/_shared/ui/index";
import user from "./modules/user/index";
import shipment from "./modules/shipment/index";
import vehicle from "./modules/vehicle/index";
import notification from "./modules/notification/index";
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules: {
    dashboard,
    auth,
    ui,
    user,
    shipment,
    vehicle,
    notification,
  },
  strict: debug
});

export default store;
