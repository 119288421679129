import * as vehicleActions from "./actions";

import * as vehicleApis from "../../../api/vehicle"

const state = {
  status: null,
  hasLoadedOnce: null,
  vehicleList: [],
};

const getters = {
  // getVehicleListing: state => state.vehicleList,
};

const actions = {
  [vehicleActions.VEHICLE_LIST_REQUEST]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(vehicleActions.VEHICLE_LIST_REQUEST);
      vehicleApis.getVehicleList()
      .then(resp => {
        if (resp.status != 'ok') throw (resp)
        commit(vehicleActions.VEHICLE_LIST_SUCCESS, resp.response);
        resolve(resp);
      })
      .catch(err => {
        console.log(err)
        commit(vehicleActions.VEHICLE_LIST_ERROR);
        reject(err);
      });
    })
  },
};

const mutations = {
  VEHICLE_LIST_REQUEST: (state) => {
    Object.assign(state, {
      status: 'loading'
    });
  },
  VEHICLE_LIST_SUCCESS: (state, postData) => {
    Object.assign(state, {
      vehicleList: postData
    });
  },
  VEHICLE_LIST_ERROR: (state) => {
    Object.assign(state, {
      status: "error",
      vehicleList: [],
      hasLoadedOnce: true
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
