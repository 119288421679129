import { ifAuthenticatedAndAllowed } from "../index"

// import {
//   UserManagementList, UserManagementDetail,
//   PassThrough,
// } from "../../constants/component-imports"

import {
  LAYOUT_ADMIN
} from "../../constants";

const userManagementRouter = {
  path: "/user-management",
  component: () => import("@/views/pass-through.vue"),
  meta: {
    layout: LAYOUT_ADMIN,
    permission: [
      'ROLE_ADMIN', 'ROLE_MANAGER', "ROLE_LOGISTICS_OFFICER"
    ],
  },
  children: [
    {
      path: ":index?",
      name: "View Listings",
      component: () => import("@/views/user-management/list.vue"),
      beforeEnter: (to, from, next) => ifAuthenticatedAndAllowed(to, from, next),
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          'ROLE_ADMIN', 'ROLE_MANAGER', "ROLE_LOGISTICS_OFFICER"
        ],
        title: "User Management",
        subtitles: [""]
      },
    },
    {
      path: "detail/add",
      name: "Add User",
      component: () => import("@/views/user-management/detail.vue"),
      beforeEnter: (to, from, next) => ifAuthenticatedAndAllowed(to, from, next),
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          'ROLE_ADMIN', 'ROLE_MANAGER', "ROLE_LOGISTICS_OFFICER"
        ],
        title: "User Management",
        subtitles: ["Add"]
      },
    },
    {
      path: "detail/:id",
      name: "View Details",
      component: () => import("@/views/user-management/detail.vue"),
      beforeEnter: (to, from, next) => ifAuthenticatedAndAllowed(to, from, next),
      meta: {
        layout: LAYOUT_ADMIN,
        permission: [
          'ROLE_ADMIN', 'ROLE_MANAGER', "ROLE_LOGISTICS_OFFICER"
        ],
        title: "User Management",
        subtitles: [""]
      },
    },
  ],
  beforeEnter: (to, from, next) => ifAuthenticatedAndAllowed(to, from, next)
};

export default userManagementRouter;