<template>
  <div>
    <div id="header-top" class="header">
      <div class="header-content">
        <div class="header-left d-inline-flex align-items-center" style="height: 60px; cursor: default">
          <div class="navigation-icon custom-icon" @click="toggleSidebar"></div>
          <ul class="mb-0 d-none d-sm-block">
            <li class="icons position-relative">
              <div class="d-inline-flex" :key="path">
                <p class="my-auto">{{$route.meta.title}}</p>
                &nbsp;&nbsp;
                &nbsp;&nbsp;
                <div 
                  v-if="$route.name || subtitles.length > 0" 
                  class="vertical-line"
                ></div>
                &nbsp; &nbsp;
                <p id="header-route-name" class="my-auto"> {{$route.name}}</p>
                <div v-for="(subtitle, index) in subtitles" :key="index" :class="'subtitle-' + index" class="my-auto d-inline-flex">
                  <div v-if="subtitle !== '' && $route.name !== ''">&nbsp;/&nbsp;</div>
                  <div v-if="subtitle !== ''" class="header-subtitle">{{subtitle}}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="header-right">
          <ul class="mb-0">
            <li class="icons d-inline-flex">
              <b-dropdown ref="navbar-dropdown" id="navbar-dropdown" class="m-md-2">
                <b-dropdown-item>
                  <router-link to="/profile" class="d-inline-flex w-100 m-0 py-3">
                    <div class="profile-icon custom-icon mr-3 h-100"></div>
                    <span class="my-auto">Profile</span>
                  </router-link>
                </b-dropdown-item>
                <hr class="my-3"/>
                <b-dropdown-item>
                  <div @click="onLogout" class="d-inline-flex w-100 m-0 py-3">
                    <div class="sign-out-icon custom-icon mr-3 h-100"></div>
                    <span class="my-auto">Sign Out</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
              <div class="d-inline-flex" style="cursor: pointer" @click="toggleDropdown">
                <div class="profile-icon custom-icon"></div>
                <div class="dropdown-icon custom-icon"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AUTH_LOGOUT } from "../../store/modules/auth/actions";
import { mapGetters, mapState } from "vuex";
import { EventBus } from "../../utils/EventBus";

export default {
  name: "Header",
  props: ['toggleSidebarAction'],
  computed: {
    ...mapGetters(["getUser", "isAuthenticated"]),
    ...mapState({
      authLoading: state => state.auth.status === "loading",
      name: state => `${state.auth.user.name}`,
      profilePic: state => `${state.auth.user.profilePic}`
    }),
  },
  watch: {
    $route(to) {
      if (to.fullPath !== "/products/stock-balance-list/view-user") {
        this.path = to.fullPath;
      }
      this.subtitles = []
    }
  },
  data() {
    return {
      env: process.env.VUE_APP_NODE_ENV,
      unreadCount: 0,
      path: this.$route.fullPath,
      subtitles: [],
    };
  },
  methods: {
    toggleSidebar(){
      this.toggleSidebarAction();
    },
    toggleDropdown(){
      this.$refs['navbar-dropdown'].show();
    },
    getUnreadNotifications(){
      // this.$store.dispatch(GET_UNREAD_NOTIFICATIONS_REQUEST).then(res => {
      //   if(res.status === 'ok' && res.result){
      //     this.unreadCount = res.result.unreadCount;
      //   }
      // })
    },
    onLogout() {
      this.$store.dispatch(AUTH_LOGOUT).then(res => {
        if (res.logout) {
          EventBus.$emit("userLogout", 1);
        }
      });
    },
  },
  mounted() {
    EventBus.$on("getNotificationsCount", () => {
      this.getUnreadNotifications();
    });
    this.getUnreadNotifications();
    EventBus.$on("passSubtitles", (payload) => {
      this.subtitles = payload
    })
  },
};
</script>
<style lang="scss">

.dropdown-icon{
  background-position-x: left;
}

#navbar-dropdown button.dropdown-toggle{
  display: none!important;
}

#navbar-dropdown.b-dropdown{
  width: 0;
}

#navbar-dropdown .dropdown-menu{
  top: 40px!important;
  left: -200px!important;
  width: 250px;
  padding-top: 10px;
  transform: none!important;
  border: none;
  box-shadow: 0 0 1.5rem rgb(120 130 140 / 13%);
}

#navbar-dropdown .dropdown-menu li{
  height: 50px;
}

#navbar-dropdown .dropdown-item{
  display: inline-flex;
}

#navbar-dropdown .dropdown-item span{
  color: #808997;
  font-size: 14px;
}

.header p, .header .header-subtitle{
  cursor: default;
}

#header-route-name {
  color: #808997;
}
.header-subtitle {
  color: #1F1F24
}
</style>