<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "Auth",
  computed: {
    ...mapGetters(["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState({
      authLoading: state => state.auth.status === "loading",
    })
  }
};
</script>

<template>
  <div id="auth-layout">
    <div class="loading-wrapper" v-show="authLoading"><div class="loading loading-big"></div></div>
    <slot />
  </div>
</template>