export const SHIPMENT_LIST_REQUEST = "SHIPMENT_LIST_REQUEST";
export const SHIPMENT_LIST_SUCCESS = "SHIPMENT_LIST_SUCCESS";
export const SHIPMENT_LIST_ERROR = "SHIPMENT_LIST_ERROR";

export const SHIPMENT_REQUEST = "SHIPMENT_REQUEST";
export const SHIPMENT_SUCCESS = "SHIPMENT_SUCCESS";
export const SHIPMENT_ERROR = "SHIPMENT_ERROR";

export const SHIPMENT_CANCEL_REQUEST = "SHIPMENT_CANCEL_REQUEST";
export const SHIPMENT_CANCEL_SUCCESS = "SHIPMENT_CANCEL_SUCCESS";
export const SHIPMENT_CANCEL_ERROR = "SHIPMENT_CANCEL_ERROR";

export const CANCELLABLE_SHIPMENT_LIST_REQUEST = "CANCELLABLE_SHIPMENT_LIST_REQUEST";
export const CANCELLABLE_SHIPMENT_LIST_SUCCESS = "CANCELLABLE_SHIPMENT_LIST_SUCCESS";
export const CANCELLABLE_SHIPMENT_LIST_ERROR = "CANCELLABLE_SHIPMENT_LIST_ERROR";

export const SHIPMENT_BULK_CANCEL_REQUEST = "SHIPMENT_BULK_CANCEL_REQUEST";
export const SHIPMENT_BULK_CANCEL_SUCCESS = "SHIPMENT_BULK_CANCEL_SUCCESS";
export const SHIPMENT_BULK_CANCEL_ERROR = "SHIPMENT_BULK_CANCEL_ERROR";