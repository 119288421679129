export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const PROFILE_POST_REQUEST = "PROFILE_POST_REQUEST";
export const PROFILE_POST_SUCCESS = "PROFILE_POST_SUCCESS";
export const PROFILE_POST_ERROR = "PROFILE_POST_ERROR";
export const PASSWORD_POST_REQUEST = "PASSWORD_POST_REQUEST";
export const PASSWORD_POST_SUCCESS = "PASSWORD_POST_SUCCESS";
export const PASSWORD_POST_ERROR = "PASSWORD_POST_ERROR";
export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";