import Vue from "vue";
import admin_layout from "../layouts/admin-layout.vue";
import auth_layout from "../layouts/auth-layout.vue";
import statusNotification from "../components/ui/statusNotification.vue";

// Only import those needed to reduce bundle (whole Bootstrap-vue is 1.78MB) ))
import { BDropdown, BDropdownItem } from 'bootstrap-vue/esm/components/dropdown'
import { BInputGroup } from 'bootstrap-vue/esm/components/input-group'
import { BFormInput } from 'bootstrap-vue/esm/components/form-input'
import { BCollapse } from 'bootstrap-vue/esm/components/collapse'
import { BCard } from 'bootstrap-vue/esm/components/card'
import { BButton } from 'bootstrap-vue/esm/components/button'
import { BAlert } from 'bootstrap-vue/esm/components/alert'
import { BTabs, BTab } from 'bootstrap-vue/esm/components/tabs'
import { BCarousel, BCarouselSlide } from 'bootstrap-vue/esm/components/carousel'
import { BFormSelect } from 'bootstrap-vue/esm/components/form-select'
import { BModal } from 'bootstrap-vue/esm/components/modal'
import { VBToggle } from 'bootstrap-vue/esm/directives/toggle'

import RouterPrefetch from 'vue-router-prefetch'
// eslint-disable-next-line no-unused-vars
import Metismenu from "metismenu";

Vue.component("admin-layout", admin_layout);
Vue.component("auth-layout", auth_layout);
Vue.component("status-notification", statusNotification);

window.Vue = Vue;

// Only import those needed (based on b-<something>)
Vue.component('b-dropdown', BDropdown)
Vue.component('b-dropdown-item', BDropdownItem)
Vue.component('b-input-group', BInputGroup)
Vue.component('b-form-input', BFormInput)
Vue.component('b-collapse', BCollapse)
Vue.component('b-card', BCard)
Vue.component('b-button', BButton)
Vue.component('b-alert', BAlert)
Vue.component('b-tabs', BTabs)
Vue.component('b-tab', BTab)
Vue.component('b-carousel', BCarousel)
Vue.component('b-carousel-slide', BCarouselSlide)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-modal', BModal)

Vue.directive('b-toggle', VBToggle)

Vue.use(RouterPrefetch, {
  prefetch: true,
});
