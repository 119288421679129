import request from "@/utils/request";
import * as apiConstants from "../constants/services";
import { DEFAULT_TIMEOUT } from "../constants/index";

export async function loginByUsername(authData) {
  return await request({
    url: apiConstants.API_LOGIN_SERVICE,
    method: "post",
    data: authData,
    timeout: DEFAULT_TIMEOUT,
  });
}

export async function resetByEmail(postData) {
  return await request({
    url: apiConstants.API_FORGOT_PW_SERVICE,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updateProfile(postData) {
  return await request({
    url: apiConstants.API_UPDATE_USER_PROFILE,
    method: "put",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}

export async function updatePassword(postData) {
  return await request({
    url: apiConstants.API_UPDATE_USER_PASSWORD,
    method: "post",
    data: postData,
    timeout: DEFAULT_TIMEOUT
  });
}
